import { Environment, EnvironmentName } from "../models/environment";
import { envShared } from "./shared";

export default function envProd(): Environment {
    return {
        env: EnvironmentName.prod,
        bot: {
            name: envShared.bot.name
        },
        protocol: envShared.protocol,
        url: {
            axp: {
                host: envShared.url.axp.host,
            },
            api: {
                host: 'chat.avaya.com:443',
                entrypoint: envShared.url.api.entrypoint,
            },
            static: {
                host: 'onecare.avaya.com',
                entrypoint: envShared.url.static.entrypoint,
            },
            gTranslate: {
                host: 'chat.avaya.com',
            },
            cloudFunction: {
                host: envShared.url.cloudFunction.host
            }
        },
        default_queue:envShared.default_queue,
        mandatory_properties: {
            'function': ['1397259904096'], 
            // 'category': ['1399863147537']
          }
    };
}