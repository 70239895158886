import React from 'react';
import { ToggleWrapper } from './toggle.styled';
import { ToggleClosed } from './toggle-closed';
import { ToggleOpened } from './toggle-opened';
import { useProcessingContext } from '../../contexts/processing';
import { useUserContext } from '../../contexts/user';
import { useToggleContext } from '../../contexts/toggle';
import { useEngagementContext } from '../../contexts/engagement';
import { useNewMsgContext } from '../../contexts/msg';
import initializeSDK, { shutdownSDK, stage } from '../../axp/sdk';
import { useStatusContext } from '../../contexts/status';
import { useLanguageContext } from '../../contexts/language';

let count = 0;

function Toggle() {
   const processingCtxt = useProcessingContext();
   const statusCtxt = useStatusContext();
   const user = useUserContext();
   const toggle = useToggleContext();
   const newMsgCtxt = useNewMsgContext();
   const engagementCtxt = useEngagementContext();
   const languageCtxt = useLanguageContext();
   
   
   async function onClick() {
      if (!toggle) throw new Error('No toggle context');

      toggle.isOpen ? shutdown() : init();
      toggle.onClick();
   }

   async function shutdown(): Promise<void> {
      count++;
      const counter = count;
      console.debug(`%c SDK shutdown ${counter} called by toggle:`, 'background: #595959', stage.state);
      await shutdownSDK(processingCtxt, statusCtxt, newMsgCtxt, engagementCtxt, languageCtxt, true);
      console.debug(`%c SDK shutdown ${counter} resolved:`, 'background: #595959', stage.state);
   }

   async function init(): Promise<void> {
      count++;
      const counter = count;
      console.debug(`%c SDK init ${counter} called by toggle:`, 'background: #595959', stage.state);
      await initializeSDK(user, processingCtxt, statusCtxt, newMsgCtxt, engagementCtxt, languageCtxt);
      console.debug(`%c SDK init ${counter} resolved:`, 'background: #595959', stage.state);
   }

   return (
      <ToggleWrapper>
         <button onClick={ onClick }>
            { toggle?.isOpen ? <ToggleOpened /> : <ToggleClosed /> }
         </button>
      </ToggleWrapper>
   )
}

export default Toggle;
