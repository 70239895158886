import styled from 'styled-components';

export const MessageWrapper = styled.div`

    .message-block {
        display: flex;
        align-items: flex-end;
        gap: 8px;
        word-break: break-word;
    }

    .message-block .message-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: #e6e6e6;
        border-radius: 50%;
    }

    .message-block .message-avatar > * {
        height: 40px;
        font-size: 25px;
    }

    .message-block .message-avatar > .neo-icon-socket-connected {
        height: 39px;
    }

    .message-block .message-avatar > .neo-icon-bot {
        height: 40px;
    }

    .message-block .message-avatar > .neo-icon-agents {
        height: 36px;
    }

    .message-block .message-avatar > .neo-icon-contact-filled {
        height: 39px;
    }

    .message-block.message-incoming {
        padding-right: 48px;
    }

    .message-block.message-outgoing {
        color: #05474d;
        padding-left: 48px;
        flex-direction: row-reverse;
    }

    .message-block.message-outgoing .message-avatar {
        background: var(--avaya_teal);
    }

    .message-block.message-UI .message-avatar {
        background: transparent;
    }

    .message-block .message-content {
        flex: 1;
        display: flex;
        padding-bottom: 2.5px;
    }

    .message-block.message-outgoing .message-content {
        justify-content: flex-end;
    }

    .message-block .message-content .message-text {
        font-family: var(--font_family);
        font-size: var(--font_size_normal);
        white-space: pre-line;
        max-width: 247px;
        background: white;
        border: none;
        color: black;
        text-align: left;
        border-radius: 3px;
        padding: 10px;
        box-shadow: var(--shadow_msg);
    }

    .message-block .message-content .message-text > a {
        color: var(--avaya_teal);
        text-decoration: underline;
    }

    .message-CUSTOMER .message-content .message-text > a {
        color: var(--avaya_grey_light);
    }
    
    .message-block .message-content .message-text > a:hover {
        color: var(--cct_turquoise);
    }
    
    .message-CUSTOMER .message-content .message-text > a:hover {
        color: var(--avaya_grey);
    }    

    .message-block.message-outgoing .message-content .message-text {
        background: var(--avaya_teal);
        color: white;
    }

    .message-block .message-content .message-body.message-body-UI .message-text {
        background: var(--avaya_grey);
        color: var(--avaya_grey_dark);
    }
`;
