import config from "../config/config";
import { SUPPORTED_LANGUAGES, SupportedLanguage, TranslationConfig } from "../models/language";



export function getSupportedLanguage(field: string, value: string): SupportedLanguage | null {
    const matchingLanguage = SUPPORTED_LANGUAGES.find((languageObject:SupportedLanguage):boolean => {
        return languageObject[field] === value;
    });

    return matchingLanguage || null;
    }

export async function translateText(textToTranslate: string, translationConfig: TranslationConfig): Promise<any> {
    console.log('translateText textToTranslateV3:', textToTranslate);
    console.log('translateText languageToTranslateToV3:', translationConfig.targetLanguage);
    console.log('translateText languageToTranslateFromV3:', translationConfig.sourceLanguage);
    const googleTranslateUrl =  config().url.gTranslate.base
    try {
        const response = await fetch(`${googleTranslateUrl}/eptcct-google-translate/api/googletranslate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                text: textToTranslate,
                ...translationConfig
            })
        });
        const data = await response.json();
        console.log('translateText v3 response:', data);
        return data.translation;
    } catch (error) {
        console.error('Error translating text v3:', error);
        return null;
    }
}