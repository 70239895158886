import { MSGItemBody } from "./msg";
import { SDKHumanParticipantType, SDKMessage } from "./sdk";
import { UserContextType } from "./user";

export type LanguageCode = 'en-us' | 'es-ES' | 'fr-FR' | 'de' | 'it' | 'nl' | string | null


export type SupportedLanguage = {
    apiKeyName: string,
    customerLeftConversationMessage: string,
    detectLanguageCode: string,
    displayName: string,
    languageCode: string,
    notificationMessage: string,
    [key: string]: string,
};

export const SUPPORTED_LANGUAGES: Array<SupportedLanguage> = [
    {
        apiKeyName: 'German',
        customerLeftConversationMessage: 'Der Kunde hat den Chat verlassen.',
        detectLanguageCode: 'de',
        displayName: 'Deutsch',
        languageCode: 'de',
        notificationMessage: 'Momentan sind keine deutschsprachigen Agenten verfügbar, daher werden automatische Übersetzungsdienste verwendet, um Ihren Chat mit unserem Support-Spezialisten zu unterstützen.',
    },
    {
        apiKeyName: 'English',
        customerLeftConversationMessage: 'The customer left the chat.',
        detectLanguageCode: 'en',
        displayName: 'English',
        languageCode: 'en-us',
        notificationMessage: 'There are no English speaking agents available at this time, so automatic translation services will be used to support your chat with our Support Specialist.',
    },
    {
        apiKeyName: 'Spanish',
        customerLeftConversationMessage: 'El cliente ha abandonado el chat.',
        detectLanguageCode: 'es',
        displayName: 'Español',
        languageCode: 'es-ES',
        notificationMessage: 'En este momento no hay agentes que hablen español disponibles, por lo que se utilizarán servicios de traducción automática para apoyar su chat con nuestro Especialista de Soporte.',
    },
    {
        apiKeyName: 'French',
        customerLeftConversationMessage: 'Le client a quitté le chat.',
        detectLanguageCode: 'fr',
        displayName: 'Français',
        languageCode: 'fr-FR',
        notificationMessage: "À l'heure actuelle, aucun agent francophone n'est disponible, des services de traduction automatique seront donc utilisés pour soutenir votre discussion avec notre spécialiste du support.",
    },
    {
        apiKeyName: 'Italian',
        customerLeftConversationMessage: 'Il cliente ha lasciato la chat.',
        detectLanguageCode: 'it',
        displayName: 'Italiano',
        languageCode: 'it',
        notificationMessage: "Al momento non sono disponibili agenti che parlino italiano, pertanto per supportare la chat con il nostro specialista dell'assistenza verranno utilizzati servizi di traduzione automatica.",
    },
    {
        apiKeyName: 'Dutch',
        customerLeftConversationMessage: 'De klant heeft de chat verlaten.',
        detectLanguageCode: 'nl',
        displayName: 'Nederlands',
        languageCode: 'nl',
        notificationMessage: 'Momenteel zijn er geen Nederlandstalige medewerkers beschikbaar. Daarom maken we gebruik van automatische vertaaldiensten om uw chat met onze ondersteuningsspecialist te ondersteunen.',
    },
] as const;


export type TranslationConfig = {
    sourceLanguage: LanguageCode,
    targetLanguage: LanguageCode
}

export interface LanguageContextType {
    AgentLanguage: LanguageCode;
    UserLanguage: LanguageCode;
    status:boolean, 
    setStatus:(status:boolean)=>void;
    setAgentLanguage: (language: LanguageCode) => void;
    setUserLanguage: (language: LanguageCode) => void;
    translateMessage: (message: string | MSGItemBody, sender: SDKHumanParticipantType) => Promise<string>
    saveTranscript: (message:string, messageId: string, from:SDKHumanParticipantType, user:UserContextType, engagementId: string) => void
    translateMessageWithLanguages: (message: string | MSGItemBody, sender: SDKHumanParticipantType, userLanguage: LanguageCode, agentLanguage: LanguageCode) => Promise<string>
    getLanguageByString: (message:string)=> LanguageCode | null
}