import React from 'react';
import StatusProvider from '../../contexts/status';
import EngagementProvider from '../../contexts/engagement';
import MsgProvider from '../../contexts/msg';
import LanguageProvider from '../../contexts/language'
import { WidgetWrapper } from './widget.styled';
import { useToggleContext } from '../../contexts/toggle';
import PopUp from '../pop-up/pop-up'
import Toggle from '../toggle/toggle'

function Widget() {
   const toggle = useToggleContext();

   return (
      <StatusProvider>
         <EngagementProvider>
            <LanguageProvider>
               <MsgProvider>
                  <WidgetWrapper>
                     <div className={`cct-chat-widget ${toggle?.isOpen ? 'cct-chat-widget-opened' : ''}`}>
                        {toggle?.isOpen ? <PopUp /> : null}
                        <Toggle />
                     </div>
                  </WidgetWrapper>
               </MsgProvider>
            </LanguageProvider>
         </EngagementProvider>
      </StatusProvider>
   );
}

export default Widget;
