import React, { createContext, useState } from 'react';
import { LanguageCode, LanguageContextType, TranslationConfig, SUPPORTED_LANGUAGES } from '../models/language';
import { MSGItemBody } from '../models/msg';
import { translateText } from '../services/google-translate';
import { SDKHumanParticipantType, SDKParticipantType } from '../models/sdk';
import { ProviderParams } from '../models/react';
import { UserContextType } from '../models/user';
import config from '../config/config';

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);
export function useLanguageContext() {
    return React.useContext(LanguageContext);
}

export default function LanguageProvider({ children }: ProviderParams) {


    const [AgentLanguage, setAgentLanguage] = useState<LanguageCode>(null);
    const [UserLanguage, setUserLanguage] = useState<LanguageCode>(null);
    const [status, setStatus] = useState<boolean>(false)
    const translateMessage = async (message: string | MSGItemBody, sender: SDKHumanParticipantType): Promise<string> => {
        return translateMessageWithLanguages(message, sender, UserLanguage, AgentLanguage)
    }
    async function saveTranscript(message:string, messageId: string, from:SDKHumanParticipantType, user:UserContextType, engagementId: string) {
        const url = `${config().url.api.saveTranscript}`
        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    "body": message,
                    "engagementId": engagementId,
                    "from": from,
                    "messageId":`${messageId}`,
                    "userIdentifier": user.firstName + " " + user.lastName
                }),
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
            return data
        } catch (error:any) {
            console.error('Error:', error.message);
        }
    }
    const translateMessageWithLanguages = async (message: string | MSGItemBody, sender: SDKHumanParticipantType, userLanguage: LanguageCode, agentLanguage: LanguageCode) => {
        const config = getTranslationConfig(sender, agentLanguage, userLanguage)
        if (agentLanguage && userLanguage && agentLanguage !== userLanguage && typeof message === 'string' && config) {
            const translation = await translateText(message, config)
            return translation
        } else {
            return ''
        }
    }
    const getLanguageByString = (message: string): LanguageCode | null => {
        const language = SUPPORTED_LANGUAGES.find((elem) => (elem.displayName === message || elem.apiKeyName === message ))
        if (language && language.languageCode) return language.languageCode as LanguageCode
        return null
    }

    return (
        <LanguageContext.Provider value={{ status, setStatus, AgentLanguage, UserLanguage, setAgentLanguage, setUserLanguage, translateMessage, getLanguageByString, translateMessageWithLanguages, saveTranscript }}>
            {children}
        </LanguageContext.Provider>
    );

};

export { LanguageContext, LanguageProvider };

function getTranslationConfig(sender: SDKHumanParticipantType, agentLanguage: LanguageCode, userLanguage: LanguageCode): TranslationConfig | null {
    if (sender === SDKParticipantType.AGENT) {
        return {
            sourceLanguage: agentLanguage,
            targetLanguage: userLanguage
        }
    }
    if (sender === SDKParticipantType.CUSTOMER) {
        return {
            sourceLanguage: userLanguage,
            targetLanguage: agentLanguage
        }
    }

    return null

}

