import { Environment, EnvironmentName } from "../models/environment";

export const envShared: Environment = {
    env: EnvironmentName.dev,
    bot: {
        name: 'Avaya Cloud Office Bot'
    },
    protocol: 'https:',
    url: {
        axp: {
            host: 'na.cc.avayacloud.com',
        },
        api: {
            host: '135.11.242.99:8080',
            entrypoint: 'BackendAvayaCC/api',
        },
        static: {
            host: 'cms.avaya.com',
            entrypoint: 'cs/bot/cct-chat-widget/build',
        },
        gTranslate: {
            host: '135.11.242.99',
        },
        cloudFunction: {
            host: '135.11.242.99'
        }
    },
    default_queue: "aco_english",
    mandatory_properties: {
        'function': ['1397259904096'], 
        'category': ['1399863147537']
    }
}

export const envEndpoint = {
    api: {
        backend: '/backendavayacc',
        firestore: '/firestoreData',
        saveTranscript: '/eptcct-axp-getEngagement/api/saveTranscript',
        engagementLanguage: '/eptcct-axp-getEngagement/api/getEngagement'
    },
    static: {
        media: '/static/media',
    }
}