export enum EnvironmentName {
  dev = "dev",
  uat = "uat",
  prod = "prod",
}
export interface Environment {
  env: EnvironmentName;
  bot: EnvironmentBot;
  protocol: string;
  url: EnvironmentUrls;
  default_queue:string,
  mandatory_properties:{
    [key:string]: Array<string>
  };
}

export interface EnvironmentBot {
  name: string;
}

export interface EnvironmentUrls {
  axp: EnvironmentUrl;
  api: EnvironmentUrl;
  static: EnvironmentUrl;
  [key: string]: EnvironmentUrl;
}

export interface EnvironmentUrl {
  host: string;
  entrypoint?: string;
  protocol?: string;
}

export interface EnvironmentConfig {
  env: EnvironmentName;
  bot: {
    name: string;
  };
  url: {
    axp: {
      base: string;
    };
    api: {
      base: string;
      auth: string;
      firestore: string;
      saveTranscript: string;
      engagementLanguage:string
    };
    static: {
      base: string;
      assets: string;
    };
    gTranslate: {
      base: string;
    }, 
    cloudFunction: {
      base: string;
    }
  };
  mandatory_properties:{
    [key:string]: Array<string>
  };
  default_queue:string,
  getMediaUrl: (name: string) => string;
}

